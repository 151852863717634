<template>
<div>
    <NavBar/> 
		<router-view ></router-view>
    <Footer/>
    <Popup />
</div>
</template>

<script>
import Home from './views/Home';
import NavBar from './components/Nav-bar.vue';
import Footer from './components/Footer.vue';
import Popup from './components/Pop-up.vue';

export default {
  name: 'App',

  components: {
    Home,
    NavBar,
    Footer,
    Popup
  },

  data: () => ({
    //
  }),
};
</script>
