<template>
	<!-- component -->
	<div id="app">
	  <footer class="footer-distributed">
		<div class="footer-left">
		  <h3>{{ $t('companyName') }}</h3>
		</div>
  
		<div class="footer-center">
		  <div>
			<i :class="$t('location.icon')"></i>
			<p><span>{{ $t('location.address') }}</span></p>
		  </div>
		  <div>
			<i :class="$t('email.icon')"></i>
			<p><a :href="$t('email.address')">{{ $t('email.address') }}</a></p>
		  </div>
		  <div>
			<i :class="$t('phone.icon')"></i>
			<p><a :href="'tel:' + $t('phone.number')">{{ $t('phone.number') }}</a></p>
		  </div>
		</div>
  
		<div class="footer-right">
		  <p class="footer-company-about">
			<span>{{ $t('aboutCompany') }}</span>
		  </p>
		  <div class="footer-icons">
			<a :href="$t('socialMedia.linkedin')"><i class="fa fa-linkedin"></i></a>
			<a :href="$t('socialMedia.youtube')"><i class="fa fa-youtube-play"></i></a>
		  </div>
		</div>
	  </footer>
	</div>
</template>

<script>
export default {}
</script>
<style lang="css" scoped>
/*FOOTER*/
section {
  width: 100%;
  display: inline-block;
  background-color:;
  height: 80vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}
.footer-distributed{
  background:#F4F4F4;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  font: bold 16px sans-serif;
  padding: 55px 50px;
}
.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */
.footer-distributed .footer-left{
	width: 40%;
}
/* The company logo */
.footer-distributed h3{
	color:  #282B69;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 36px;

	margin: 0;
}

.footer-distributed h3 span{
	color:  #ED2330;
}

/* Footer links */

.footer-distributed .footer-links{
	color:  #ffffff;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a{
	display:inline-block;
	line-height: 1.8;
  font-weight:400;
	text-decoration: none;
	color:  inherit;
}

.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center{
	width: 35%;
}

.footer-distributed .footer-center i{
	color: #333;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}
.footer-distributed .footer-center i.fa-envelope{
	font-size: 17px;
	line-height: 38px;
}
.footer-distributed .footer-center p{
  display: inline-block;
  color: #333;
  font-weight:400;
  vertical-align: middle;
  margin:0;
}
.footer-distributed .footer-center p span{
  display:block;
  font-weight: normal;
  font-size:14px;
  line-height:2;
}

.footer-distributed .footer-center p a{
   color:#333;	
   text-decoration: none;;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */
.footer-distributed .footer-right{
	width: 20%;
}

.footer-distributed .footer-company-about{
	line-height: 20px;
	color:  #92999f;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span{
	display: block;
	color:  #333;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons{
	margin-top: 25px;
	color: #333;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;
	background-color:  #282B69;
	border-radius: 2px;
	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}
  
</style>