<template>
    <div v-if="!cookieAccepted" class="popup">
      <div class="popup-content">
        <div class="popup-header">
          <h2>{{ $t('cookiepolicyTitle') }}</h2>
        </div>
        <p>
        {{ $t('cookiepolicyDescription') }}
          <a class="popup-link" href="Intheca_KVKK_Aydınlatma_Metni.pdf" target="_blank">{{ $t('cookiepolicyKVK') }}</a>,
          <a class="popup-link" href="Intheca_Gizlilik_Cerez_Politikası.pdf" target="_blank">{{ $t('cookiepolicy') }}</a>
        </p>
        <div class="popup-footer">
          <button class="close-btn" @click="closePopup">{{ $t('cookiepolicyClose') }}</button>
          <button class="accept-btn" @click="acceptCookies">{{ $t('cookiepolicyAccept') }}</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        cookieAccepted: localStorage.getItem('cookieAccepted') === 'true',
      };
    },
    methods: {
      closePopup() {
        this.cookieAccepted = false; // Popup kapatıldığında çerez kabul edilmiş sayalım.
      },
      acceptCookies() {
        this.cookieAccepted = true;
        localStorage.setItem('cookieAccepted', 'true');
        // Çerez ayarlarını kontrol etmek için ilgili işlemleri yapabilirsiniz.
      },
    },
    mounted() {
      // Sayfa yüklendiğinde pop-up'ı göster
      if (!this.cookieAccepted) {
        // Kullanıcı daha önce çerezleri kabul etmemişse pop-up'ı göster.
      }
    },
  };
  
  </script>
  
  <style scoped>
  .popup {
    position: fixed;
    bottom: 0px;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px;
  }
  
  .popup-content {
    text-align: center;
    color: white;
    width: 100%;
  }
  
  .popup-header {
    text-align: center;
    margin-bottom: 15px;
  }
  
  .popup-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }
  
  .popup-link {
    text-decoration: underline;
  }
  
  .close-btn,
  .accept-btn {
    padding: 10px 15px;
    margin-left: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  .close-btn {
    background-color: #ccc;
    margin-right: 10px;
  }
  
  .accept-btn {
    background-color: #ff0000;
    color: #fff;
  }
  </style>