<template>
	<div class="relative flex flex-col items-center max-w-lg gap-4 p-6 rounded-md sm:py-8 sm:px-12 text-gray-800 border-none">

        <img src="../assets/img/logo.png" alt="">
		<h2 class="text-2xl font-semibold leading-tight tracking-wide">Çok yakında.</h2>
		<p class="flex-1 text-center text-gray-600">Sitemiz yapım aşamasındadır.</p>
		<button type="button" class="px-8 py-3 font-semibold rounded-full bg-orange-600 text-gray-50"><router-link to="/">Ana sayfaya dön.</router-link></button>
	</div>
</template>
<script>
export default {
    
}
</script>
