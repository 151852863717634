<template>
  <v-app>
    <HomeSection></HomeSection>
    <AboutDescription></AboutDescription>
    <CardSection></CardSection>
    <KatalogSection></KatalogSection>
    <Section></Section>
  </v-app>
</template>

<script>
import HomeSection from '../components/HomeSection';
import AboutDescription from '../components/AboutSection';
import CardSection from '../components/Card';
import KatalogSection from '../components/KatalogSection';
import Section from '../components/Section';

export default {
  name: 'App',

  components: {
    HomeSection,
    AboutDescription,
    CardSection,
    KatalogSection,
    Section
  },

  data: () => ({
    //
  }),
};
</script>


<style >
v-app{
  z-index: 0;
}
</style>