import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ComingSoon from "../views/ComingSoon.vue"


const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/contact/',
    component: () => import( '../components/Contact/ContactForm.vue')
  },
  {
    path: '/about/',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/our-team/',
    component: () => import( '../views/ComingSoon.vue')
  },
  {
    path: '/blog/',
    component: () => import( '../components/Blog/Blog.vue')
  },
  {
    path: '/document/',
    component: () => import( '../components/Document/Document.vue')
  },
  {
    path: '/career',
    component: () => import( '../views/Career.vue')
  },
  {
    path: '/iot-devices/SEM',
    component: () => import( '../views/IoT-Devices/SEM.vue')
  },
  {
    path: '/iot-devices/PMU',
    component: () => import( '../views/IoT-Devices/PMU.vue')
  },
  {
    path: '/iot-devices/DIO',
    component: () => import( '../views/IoT-Devices/DIO.vue')
  },
  {
    path:'/iot-devices/VGW',
    component: () => import('../views/IoT-Devices/VGW.vue')
  },
  {
    path: '/iot-devices/IoTDevices',
    component: () => import( '../views/IoT-Devices/IoTDevice.vue')
  },
  {
    path: '/pdf',
    component: () => import( '../views/c.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
